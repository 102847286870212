<template>
  <!-- pages/cli-sessions/[id].vue -->
  <div style="position: relative" >
    <div class="container-fluid ps-0 pe-0" >
      <div class="row ms-0 me-0 stretch-vertical">
        <div class="col col-10 border-right-and-left ">
          <Dagify :conv_id="conv_id" v-if="conv_id != 'all'" />
          <CLISessionList :sessions="sessions_ref.valueOf()" v-if="(conv_id == 'all')" @notify-delete="reload" :key="'cli_session_list_' + sessions_ref.valueOf().length" class="cli_margin_top"/>
          <div v-if="show_no_session_found_ref.valueOf()">No CLI sessions found.</div>
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>

useSeoMeta({
  title: 'Dagknows - CLI Sessions Listing',
  ogTitle: 'Dagknows - CLI Sessions Listing',
  description: 'GenAI created and expert curated automation',
  ogDescription: 'GenAI created and expert curated automation',
  ogImage: 'https://www.dagknows.com/wp-content/uploads/2021/08/banner.svg',
  twitterCard: 'https://www.dagknows.com/wp-content/uploads/2021/08/banner.svg',
});

useHead({
  script: [
    {
      src: "/vstatic/javascript/skulpt/skulpt.js",
      type: "text/javascript",
      async: true,
    },
    {
      src: "/vstatic/javascript/skulpt/skulpt-stdlib.js",
      type: "text/javascript",
      async: true,
    },
    {
      src: "/vstatic/javascript/plotly-latest.min.js",
      type: "text/javascript",
      async: true,
    },
    {
      src: "/vstatic/javascript/jsonview.js",
      type: "text/javascript",
      async: true,
    }
  ]
});

const route = useRoute();
const conv_id = route.params.id;
let sessions_ref = ref([])
let show_no_session_found_ref = ref(false);

const force_rerender_route_state = useForceRerenderRouteState();
watch(force_rerender_route_state, async (newValue, oldValue) => {
  await showWaitPage();
  loadCLISessions();
});

onMounted(async () => { 
  if (typeof ($) == 'undefined') {
    await loadScript('/vstatic/zepto-1.2.0/zepto.min.js');      
  }
  if (conv_id == 'all') {
    await showWaitPage();
    loadCLISessions();
  }
  pageMountedCode();
});

onBeforeUnmount(async () => {
    pageUnmoutedCode();
});


const loadCLISessions = () => {
  // Here, we have to use a setTimeout because when the user select a few sessions and click on the delete button, 
  // our delete_sessions functions inside CLISessionList.vue, and there we used 'await' everywhere, and we emit the 
  // notify-delete event after the deletion had been done.  The notify-delete event cause the reload function to be 
  // invoked, which then cause this function to call fetchCLISessions.  Again, we use 'await' everywhere, but what 
  // we are seeing is that the fetchCLISessions returns result as though the deletion didn't happen.  If we reload 
  // the page, we see that the deletion did happen.  So, here we use setTimeout to give the backend a chance to 
  // do what it was told to do.  I am not quite sure why we need to use setTimeout here, but that is the reason.
  setTimeout(async () => {
    let response = await fetchCLISessions();
    sessions_ref.value = response.threads.filter((session) => {
      if (session.id.startsWith('tconv_')) {
        return false;
      } else if (session.id.startsWith('__dags')) {
        return false;
      } else if (session.id == 'all') {
        return false;
      } else {
        return true;
      }
    });
    hideWaitPage();
    if (sessions_ref.value.length == 0) {
      show_no_session_found_ref.value = true;
    } else {
      show_no_session_found_ref.value = false;
    }
  }, 1000)
}

const reload = async () => {
  loadCLISessions();
}
await generateCSRFToken();
</script>

<style scoped>
.border-right-and-left {
  /* border-left: 1px solid #ccc;
  border-right: 1px solid #ccc; */
  padding-left: 0px;
  padding-right: 0px;
  margin: 20px auto;
}
.cli_margin_top {
  margin-top: 20px;
}
</style>
